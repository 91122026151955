<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="max-w-screen-md p-2" v-html="content" />
</template>

<script setup lang="ts">
const { activeMenu } = await useMenu();
activeMenu.value = "home";
const { data: content } = await useApi("/api/mainPage");
</script>
